<template>
  <v-sheet class="offre-wrapper" height="100%">
    <v-container class="" style="padding: 2.5rem 0">
      <h1 class="text-center display-3 font-weight-medium">Offre</h1>
      <div class="d-flex">
        <div
          class="content-wrapper fill-width d-flex flex-column align-center"
          style="margin-top: 5rem"
        >
          <div
            class="first mb-10 d-flex flex-column flex-md-row align-center justify-space-between"
          >
            <div
              class="content-text"
              :class="$vuetify.breakpoint.lgAndDown ? 'w-75' : 'w-35'"
            >
              <p class="mt-5 text-center text-md-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum
              cumque maxime modi ea, earum odit sunt natus beatae consectetur?
            </p>
            <p class="mt-5 text-center text-md-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
              debitis architecto at dicta laudantium iure ullam eos quam est velit
              rerum enim in perspiciatis porro aliquam voluptas aperiam. Sapiente,
              corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Nihil consectetur quis minima voluptates accusantium non officia
              esse expedita facere, maiores atque voluptatibus minus dolore
              itaque, fugit fugiat doloremque temporibus nam.
            </p>
            </div>
            <div class="content-image mt-10 d-flex align-center">
              <v-img
                src="~@/assets/illustrations/illmoy-02.jpg"
                :aspect-ratio="1"
                :width="imageWidth"
                contain
              ></v-img>
            </div>
          </div>
          <div
            class="second mb-10 d-flex flex-column flex-md-row align-center justify-space-between"
          >
            <div
              class="content-text order-1 order-md-2"
              :class="$vuetify.breakpoint.lgAndDown ? 'w-75' : 'w-35'"
            >
              <p class="mt-5 text-center text-md-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum
              cumque maxime modi ea, earum odit sunt natus beatae consectetur?
            </p>
            <p class="mt-5 text-center text-md-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
              debitis architecto at dicta laudantium iure ullam eos quam est velit
              rerum enim in perspiciatis porro aliquam voluptas aperiam. Sapiente,
              corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Nihil consectetur quis minima voluptates accusantium non officia
              esse expedita facere, maiores atque voluptatibus minus dolore
              itaque, fugit fugiat doloremque temporibus nam.
            </p>
            </div>
            <div
              class="content-image mt-10 d-flex align-center order-2 order-md-1"
            >
              <v-img
                src="~@/assets/illustrations/illmoy-04.1.jpg"
                :aspect-ratio="1"
                :width="imageWidth"
                contain
              ></v-img>
            </div>
          </div>
          <div
            class="third mb-10 d-flex flex-column flex-md-row align-center justify-space-between"
          >
            <div
              class="content-text"
              :class="$vuetify.breakpoint.lgAndDown ? 'w-75' : 'w-35'"
            >
              <p class="mt-5 text-center text-md-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum
              cumque maxime modi ea, earum odit sunt natus beatae consectetur?
            </p>
            <p class="mt-5 text-center text-md-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
              debitis architecto at dicta laudantium iure ullam eos quam est velit
              rerum enim in perspiciatis porro aliquam voluptas aperiam. Sapiente,
              corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Nihil consectetur quis minima voluptates accusantium non officia
              esse expedita facere, maiores atque voluptatibus minus dolore
              itaque, fugit fugiat doloremque temporibus nam.
            </p>
            </div>
            <div class="content-image mt-10 d-flex align-center">
              <v-img
                src="~@/assets/illustrations/illmoy-04.2.jpg"
                :aspect-ratio="1"
                :width="imageWidth"
                contain
              ></v-img>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <reservation-bar class="mt-10" />
  </v-sheet>
</template>

<script>
import ReservationBar from '../../components/public/ReservationBar.vue';
export default {
  components: { ReservationBar },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "400px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "750px";
        case "xl":
          return "750px";
      }
    },
  },
};
</script>

<style>
.w-75 {
  width: 75%;
}

.w-35 {
  width: 35%;
}
</style>
